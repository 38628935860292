import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import { Container } from "react-bootstrap"
import Doctolib from "../components/doctolib"

const GeneratedBlogPage = ({ data, pageContext }) => {
  const { title, description, image } = data.markdownRemark.frontmatter
  const __html = data.markdownRemark.html

  return (
    <Layout>
      <Seo title={title} description={description} />

      <Container style={{ maxWidth: "756px", paddingTop: "20px" }}>
        <Img
          fluid={image.childImageSharp.fluid}
          style={{
            maxWidth: "756px",
          }}
        />
        <h1 style={{ fontWeight: "bold" }}>{title}</h1>

        <div dangerouslySetInnerHTML={{ __html }} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10vw",
            paddingBottom: "10vw",
          }}
        >
          {<Doctolib />}
        </div>
      </Container>
    </Layout>
  )
}

export default GeneratedBlogPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        categories
        image {
          childImageSharp {
            fluid(cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
